import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';

import { useQuery } from '../hooks/graph';
import MeDashboardActionList from './me-dashboard-action-list';
import MeDashboardActionListChart from './me-dashboard-action-list-chart';
import MeDashboardWelcome from './me-dashboard-welcome';

const createdAtTo = Date.now() - 24 * 60 * 60 * 1000; // 24hr
const controller = new AbortController();

function MeDashboard() {
	const [ state, setState ] = useState({
		// dataProviderNames,
		// welcome,
	});

	const { data: dataAccount } = useQuery('account', controller);
	const { data: dataConnectors } = useQuery('connectors', controller);
	// obsoleted / const { data: dataHooks } = useQuery('hooks', controller);
	const { data: dataActionsOrderCounters } = useQuery('actionsOrderCounters', {variables: {
		code: 'OrderCreate',
		isTest: true,
		createdAtTo,
	}}, controller);

	useEffect(() => {
		if (dataConnectors) {
			const dataProviderNames = dataConnectors?.connectors.reduce(
				(providerNames, item) => providerNames.add(item.providerName), new Set()
			);

			setState({dataProviderNames, welcome: dataProviderNames?.size == 0}); // reserved / && window.location.search.includes('welcome')
		}
	}, [dataConnectors]); // abort on unmount

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return dataConnectors && dataActionsOrderCounters ? (
		<div id="dashboard">
			<h3 className="my-3">Dashboard</h3>

			<div className="row">
				<div className="col-6 col-md-4">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{state.dataProviderNames ? state.dataProviderNames.size : <span>&nbsp;</span>}</h2>
						<h6>Connected brokers</h6>
					</div>
				</div>
				<div className="col-6 col-md-4">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{dataAccount ? Math.max(dataAccount.account.credits || 0, 0) : <span>&nbsp;</span>}{/* Yeah, it may "technically" go below zero, cause of "recurrentCredits" */}</h2>
						<h6>Signals left</h6>
					</div>
				</div>
				{/*
				<div className="col-4">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{dataHooks ? dataHooks.hooks.length : <span>&nbsp;</span>}</h2>
						<h6>Created webhooks</h6>
					</div>
				</div>
				*/}
				<div className="col-4 d-none d-md-block">
					<div className="bg-light p-4 rounded-3 h-100">
						<h2>{dataActionsOrderCounters ? dataActionsOrderCounters.actionsOrderCounters[0].counter : <span>&nbsp;</span>} {dataActionsOrderCounters ? <span className="text-secondary opacity-50">/ {dataActionsOrderCounters.actionsOrderCounters[0].counterTest}</span> : <span>&nbsp;</span>}</h2>
						<h6>Signals in&nbsp;last&nbsp;24h</h6>
					</div>
				</div>
			</div>

			<div className="mt-3">
				{ dataConnectors.connectors?.length ? <MeDashboardActionListChart /> : '' }
			</div>

			<div className="mt-3">
				<MeDashboardActionList />
			</div>

			{
				state.welcome && <MeDashboardWelcome firstStep={window.location.search.includes('welcome') ? 'welcome' : 'connector'} />
			}
		</div>
	) : false;
}

export default MeDashboard;
