import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { jsonrepair } from 'jsonrepair';

import config from '../config';
import { useQuery, useMutation } from '../hooks/graph';
import { SetupContext } from '../contexts/setup';
// import { formControlInvalid } from '../components/forms';
import Spinner from '../components/spinner';
// reserved / import { setClipboard } from '../utils/misc';
// import { ClipboardIcon } from '../utils/icons';

const controller = new AbortController();

/* eslint-disable complexity */ // temporal
function MeConnector({ match, location }) {
	const { providers } = useContext(SetupContext);
	const { data: dataConnectors } = useQuery('connectors', controller);
	const [ orderPreview ] = useMutation('orderPreview', controller);

	const { register, handleSubmit, getValues, setValue, clearErrors, formState: { errors } } = useForm();
	const [ state, setState ] = useState({});
	const reference = state.reference;

	// reserved
	// const getOrderUrl = () => {
	// 	const url = new URL(config.base + '/me/documentation/' + state.provider.name);

	// 	try {
	// 		url.search = new URLSearchParams(parseOrder(getValues().order));
	// 		return url.toString();
	// 	}
	// 	catch (err) {
	// 		setState({...state, response: err});
	// 	}
	// }

	// experimental
	const getClassActions = (_class, actions) => {
		const optionActions = config.order.action.lists.option;

		if (actions.includes(optionActions[0])) {
			return _class === 'option' ? optionActions :
				actions.filter(item => !optionActions.includes(item));
		}

		return actions;
	};

	const parseOrder = order => {
		try {
			return JSON.parse(order);
		}

		catch (err) {
			try {
				const correctOrder = jsonrepair(order);
				setValue('order', correctOrder); // fix synthax error / obsoleted: ...JSON.stringify(params, null, 2)
				return JSON.parse(correctOrder);
			}
			catch {
				// reserved
				// const line = err.message.match(/line (\d+)/)?.[1];
				// const position = err.message.match(/position (\d+)/)?.[1];

				// TODO
				// highlight?

				throw err;
			}
		}
	};

	const renderOrder = (params = {}) => {
		const { defaultValues, action, type, class: _class } = params;
		let order;

		try {
			order = parseOrder(getValues().order);
		}
		catch {
			order = {};
		}

		if (_class) {
			// TODO config "default" examples for all classes
			const exampleIndex = reference.symbol.examplesClass?.indexOf(_class);
			order.symbol = reference.class.values.length > 1 ? reference.symbol.examples[exampleIndex] : reference.symbol.examples[reference.symbol.examplesAccount?.indexOf(state.providerAccount) || 0];

			if (['future', 'option', 'warrant', 'bond'].includes(_class)) { // "bond" too?
				order.class = _class;
			}
			else {
				delete order.class;
			}

			// temporal/experimental, 240226
			if (state.provider.name === 'ibkr' && _class === 'cfd') {
				order.class = _class;
			}
		}

		return JSON.stringify({
			symbol: defaultValues.symbol || order.symbol || config.order.class.examples[_class],
			action: defaultValues.action || action,
			...(['close', 'cancel'].includes(action) ? {} : {quantity: defaultValues.quantity ? parseFloat(defaultValues.quantity) : reference.quantity.examples[0]}),
			...(['limit', 'stop_limit'].includes(type) ? {limit_price: defaultValues.limit_price ? parseFloat(defaultValues.limit_price) : reference.limit_price.examples[0]} : {}),
			...(['stop', 'stop_limit'].includes(type) ? {stop_price: defaultValues.stop_price ? parseFloat(defaultValues.stop_price) : reference.stop_price.examples[0]} : {}),
			...(order.class ? {class: order.class} : {}),
		}, null, 2);
	};

	const onChangeProvider = (providerName, connectedProviderNames, extraProviderNames, defaultValues = {}) => {
		const provider = providers[providerName];
		const providerReference = provider?.reference || {};
		const providerAccounts = dataConnectors.connectors.
			filter(item => item.providerName === provider?.name).
			reduce((accounts, item) => {
				const accountsDetails = JSON.parse(item.accountsDetails);
				item.accounts.forEach(itemAccount => accounts.set(itemAccount, accountsDetails[itemAccount]));
				return accounts;
			}, new Map());

		const limitPriceRequired = !providerReference.type?.values?.includes('market');
		const typeValues = providerReference.type?.values.filter(item => config.order.type.list.includes(item)) || config.order.type.list;
		const classValue = providerReference.class.values.includes('stock') ? 'stock' : providerReference.class.values[0]; // experimental

		// experimental
		const advancedType = {...providerReference.type,
			values: providerReference.type?.values.filter(item => !typeValues.includes(item)),
		};

		// experimental
		setState({...state,
			provider,
			providerAccounts, // across all the connectors
			providerAccount: providerAccounts.keys().next().value, // selected account if any

			connectedProviderNames: connectedProviderNames || state.connectedProviderNames || [],
			extraProviderNames: extraProviderNames || state.extraProviderNames || [],

			response: undefined,

			params: {
				defaultValues,
				typeValues,

				type: typeValues[0],
				class: classValue,
				action: getClassActions(classValue, providerReference.action.values)[0],
			},

			reference: {...providerReference,
				symbol: {...providerReference.symbol,
					required: true,
					examples: providerReference.symbol?.examples || ['AAPL'],
				},
				action: {...providerReference.action,
					required: true,
					values: providerReference.action?.values || ['buy', 'sell'],
					comments: [...(providerReference.action?.comments || [])],
				},
				quantity: {...providerReference.quantity,
					required: true,
					examples: providerReference.quantity?.examples || [123],
					comments: [
						...(providerReference.action?.values.includes('close') ? ['Not required for "close" orders'] : []),
						...(providerReference.quantity_type?.values.includes('percent_of_equity') ? ['Set to 50 for 50% of equity for "percent_of_equity" orders.'] : []),
						...(providerReference.quantity?.comments || []),
					],
				},
				extra: {...providerReference.extra,
					comments: ['Custom native brokerage order properties (see the broker API)', ...(providerReference.extra?.comments || [])],
				},
				quantity_type: {...providerReference.quantity_type,
					examples: providerReference.quantity_type?.examples || ['fixed'],
					comments: ['By default: "fixed"', ...(providerReference.quantity_type?.comments || [])],
				},
				limit_price: {...providerReference.limit_price,
					required: limitPriceRequired,
					examples: providerReference.limit_price?.examples || [123.98, '"123 + 5"'],
					comments: [limitPriceRequired ? 'Required' : 'For "limit" orders only', ...(providerReference.limit_price?.comments || [])], // "required": indicate explicitly
				},
				stop_price: {...providerReference.stop_price,
					examples: providerReference.stop_price?.examples || [123.98, '"123 + 5"'],
					comments: ['For "stop" orders only', ...(providerReference.stop_price?.comments || [])],
				},
				...(providerReference.stop_price_distance && {stop_price_distance: {...providerReference.stop_price_distance,
					examples: providerReference.stop_price_distance?.examples || [123, '"123 + 5"'],
					comments: ['For "stop" orders only', ...(providerReference.stop_price_distance?.comments || [])],
				}}),
				...(providerReference.stop_price_percent && {stop_price_percent: {...providerReference.stop_price_percent,
					examples: providerReference.stop_price_percent?.examples || [1.23, '"1.23 + 5"'],
					comments: ['For "stop" orders only', ...(providerReference.stop_price_percent?.comments || [])],
				}}),
				...(providerReference.take_profit_price && {take_profit_price: {...providerReference.take_profit_price,
					examples: providerReference.take_profit_price?.examples || [123.98, '"123 + 5"'],
					comments: ['For "take profit" bracket (conditional) orders only', ...(providerReference.take_profit_price?.comments || [])],
				}}),
				...(providerReference.stop_loss_price && {stop_loss_price: {...providerReference.stop_loss_price,
					examples: providerReference.stop_loss_price?.examples || [123.98, '"123 + 5"'],
					comments: ['For "stop loss" bracket (conditional) orders only', ...(providerReference.stop_loss_price?.comments || [])],
				}}),
				...(providerReference.stop_loss_price_distance && {stop_loss_price_distance: {...providerReference.stop_loss_price_distance,
					examples: providerReference.stop_loss_price_distance?.examples || [123, '"123 + 5"'],
					comments: ['For "trailing stop loss" bracket (conditional) orders only', ...(providerReference.stop_loss_price_distance?.comments || [])],
				}}),
				...(providerReference.stop_loss_price_percent && {stop_loss_price_percent: {...providerReference.stop_loss_price_percent,
					examples: providerReference.stop_loss_price_percent?.examples || [1.23, '"123 + 5"'],
					comments: ['For "trailing stop loss" bracket (conditional) orders only', ...(providerReference.stop_loss_price_percent?.comments || [])],
				}}),
				...(advancedType.values.length && {advancedType: {...advancedType,
					examples: advancedType.examples || `"${advancedType.values[0]}"`,
					comments: ['For advanced order types only', ...(advancedType.comments || [])],
				}}),
				...(providerReference.duration && {duration: {...providerReference.duration,
					comments: [...(providerReference.duration?.comments || []), 'Definitions, ' + providerReference.duration.values.map(item => item + ': ' + config.order.duration.definitions[item]).join(', ')],
				}}),
				...(providerReference.client_id && {client_id: {...providerReference.client_id,
					examples: providerReference.client_id?.examples || ['"ID12345"'],
					comments: [...(providerReference.client_id?.comments || []), 'Extra custom order id'],
				}}),
				...(providerReference.parent_id && {parent_id: {...providerReference.parent_id,
					examples: providerReference.parent_id?.examples || ['"ID12345"'],
					comments: [...(providerReference.parent_id?.comments || []), 'For OTOCO (OCO/OCA) order group associated with the "parent" order'],
				}}),
			},
		});

		clearErrors('submit');
	};

	const onChangeProviderAccount = providerAccount => {
		setState({...state, providerAccount});
		clearErrors('submit');
	};

	const onChangeOrderClass = orderClass => {
		const classActions = getClassActions(orderClass, reference.action.values);
		const action = classActions.includes(state.params.action) ? state.params.action : classActions[0];

		const params = {...state.params, class: orderClass, action};
		setState({...state, params});
		setValue('order', renderOrder(params));
	};

	const onChangeOrderType = orderType => {
		const params = {
			...state.params,
			...(['close', 'cancel'].includes(state.params.action) && {action: 'buy'}),
			type: orderType,
		};

		setState({...state, params});
		setValue('order', renderOrder(params));
	};

	const onChangeOrderAction = orderAction => {
		const params = {
			...state.params,
			...(['close', 'cancel'].includes(orderAction) && {type: 'market'}),
			action: orderAction,
		};

		setState({...state, params});
		setValue('order', renderOrder(params));
	};

	const onSubmit = values => {
		const connectorId = dataConnectors.connectors.find(item => item.providerName === state.provider?.name && item.accounts?.includes(state.providerAccount || item.accounts[0]))?.id;
		let dataOrder;

		if (!connectorId) {
			setState({...state, response: 'Please, connect the broker to submit test orders'});
			return;
		}

		try {
			dataOrder = parseOrder(values.order);
		}
		catch (err) {
			setState({...state, response: err});
			return;
		}

		setState({...state, response: null, isLoading: true}); // uxui: to pre-hide previous reply to have a timespan between the two

		dataOrder && orderPreview({variables: {
			connectorId,
			account: state.providerAccount || null,
			data: JSON.stringify(dataOrder), // yeah, re-strigified back one
		}}, controller).then(({ data: { orderPreview: orderPreviewData } }) => {
			Object.keys(orderPreviewData).filter(item => item.startsWith('_') || orderPreviewData[item] === null).forEach(item => delete orderPreviewData[item]);
			setState({...state, response: orderPreviewData, request: values.order, isLoading: false});

		}).catch(err => {
			setState({...state, response: err, isLoading: false});
			// obsoleted / setErrorproviderName('submit', {message: 'Error: ' + err.message}); // temporal message
		});
	};

	useEffect(() => {
		reference && setValue('order', renderOrder(state.params));
	}, [reference]);

	useEffect(() => {
		if (!dataConnectors) { return; } // keep it, to prevent rendering sides

		const providerNames = Object.keys(providers).filter(item => providers[item].status);
		const userProviderNames = dataConnectors.connectors.map(item => item.providerName);

		const connectedProviderNames = providerNames.filter(item => userProviderNames.includes(item));
		const extraProviderNames = providerNames.filter(item => !userProviderNames.includes(item));

		onChangeProvider(
			connectedProviderNames.find(item => item === match.params?.providerName)
				|| connectedProviderNames[0] || extraProviderNames[0],
			connectedProviderNames,
			extraProviderNames,
			Object.fromEntries(new URLSearchParams(location.search)), // experimental / temporal?
		);
	}, [dataConnectors]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	// reserved
	// background-position: 0px 60px;
	// background-image: linear-gradient(to bottom, red 0px, red 30px, transparent 20px, transparent 100%);

	// reserved
	// var x = document.getElementById(el);
	// if (x.currentStyle)
	//     var y = x.currentStyle[styleProp];
	// else if (window.getComputedStyle)
	//     var y = document.defaultView.getComputedStyle(x,null).getPropertyValue(styleProp);
	// return y;
	// getStyle('test', 'line-height' )
	// var x = document.getElementById('order');
	// console.log('!!!currentStyle000', x, window.getComputedStyle);
	// if (x && window.getComputedStyle) {
	// 	console.log('!!!currentStyle', document.defaultView.getComputedStyle(x,null).getPropertyValue('line-height'));
	// }

	return (
		<div id="documentation">
			<h3 className="my-3">Documentation</h3>

			{ reference ? (
				<div className="mt-3">
					{
						state.connectedProviderNames.length || state.extraProviderNames.length ? (
							<div>
								<select onChange={event => onChangeProvider(event.target.value)} defaultValue={match.params.providerName} name="providerName" id="providerName" className="form-select form-select-lg">
									{ state.connectedProviderNames.map(item => <option key={providers[item].name} value={providers[item].name}>{providers[item].title}</option>) }
									{ state.connectedProviderNames.length && state.extraProviderNames.length && <option key="separator" value={state.provider?.name}>- - - - -</option> }
									{ state.extraProviderNames.map(item => <option key={providers[item].name} value={providers[item].name}>{providers[item].title}</option>) }
								</select>
							</div>
						) : false
					}

					{
						state.providerAccounts?.size > 1 ? (
							<div className="mt-2">
								<select onChange={event => onChangeProviderAccount(event.target.value)} defaultValue={state.providerAccounts.values().next().value} name="providerAccount" id="providerAccount" className="form-select">
									{
										Array.from(state.providerAccounts.keys()).map(itemAccount => {
											const accountDetails = state.providerAccounts.get(itemAccount);
											const title = accountDetails?.dataTitle || itemAccount;
											const titleSuffix = [accountDetails?.name, accountDetails?.dataType, accountDetails?.dataCurrency].filter(item => !!item).join(', ');
											return <option key={itemAccount} value={itemAccount}>{title || 'Default'} {titleSuffix ? '/' : ''} {titleSuffix || ''}</option>;
										})
									}
								</select>
							</div>
						) : false
					}

					<div className="mt-4">
						Select { reference.class.values.length > 1 ? 'asset class,' : false } order type and action to render a sample order below:

						<div className="row mt-2">
							{
								reference.class.values.length > 1 ? (
									<div className="col-12 col-md-4 mb-2 mb-md-0">
										<div className="input-group input-group-sm">
											{/* <label className="visually-hidden">Class</label> */}
											<select value={state.params.class} onChange={event => onChangeOrderClass(event.target.value)} name="orderClass" id="orderClass" className="form-select">
												{ reference.class.values.map(item => <option key={item} value={item}>{config.order.class.labels[item]}</option>) }
											</select>
											<div className="input-group-text" onClick={event => { event.preventDefault(); window.Reamaze?.popup(`/kb/${state.provider.name}/${state.provider.name}-${state.params.class}`); }} role="button">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question" viewBox="0 0 16 16">
													<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
												</svg>
											</div>
										</div>
									</div>
								) : false
							}

							<div className="col-12 col-md-4 mb-2 mb-md-0">
								<label className="visually-hidden" htmlFor="orderType">Type</label>
								<select value={state.params.type} onChange={event => onChangeOrderType(event.target.value)} name="orderType" id="orderType" className="form-select form-select-sm">
									{ state.params.typeValues.map(item => <option key={item} value={item}>{config.order.type.labels[item]}</option>) }
								</select>
							</div>

							<div className="col-12 col-md-4">
								<label className="visually-hidden" htmlFor="orderAction">Action</label>
								<select value={state.params.action} onChange={event => onChangeOrderAction(event.target.value)} name="orderAction" id="orderAction" className="form-select form-select-sm">
									{ getClassActions(state.params.class, reference.action.values).map(item => <option key={item} value={item}>{config.order.action.labels[item]}</option>) }
								</select>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<form onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')} style={{position: 'relative'}}>
							{/*
							<a onClick={() => setClipboard(getOrderUrl())} role="button" title="Share" style={{position: 'absolute', top: '0.5em', right: '0.75em'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16"><path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"></path><path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"></path></svg></a>
							*/}
							<textarea {...register('order')} id="order" rows="8" className="form-control"></textarea>

							{/*
							<div className="btn-group w-100" role="group" aria-label="">
								{
									state.platforms.map(item => [
										<input key="input" {...register('platform')} value={item} id={item} type="radio" className="btn-check" />
									,
										<label key="label" className="btn btn-outline-secondary rounded-top-0" htmlFor={item}>{item}</label>
									])
								}
							</div>
							*/}

							<div className="d-grid gap-2"><button type="submit" className="btn btn-outline-primary btn-block mt-3"><Spinner show={state.isLoading}>Test</Spinner></button></div>

							{
								state.response ? (
									<div>
										{
											state.response instanceof Error ? (
												<div className="alert alert-danger mt-3">
													<pre className="mb-0">
														{JSON.stringify({
															// id: 'ecaf9555-c414-48f9-b125-f67e8aad9cd6',
															status: 'Error', // typeof state.response,
															message: state.response.message,
														}, null, 2)}
													</pre>
												</div>
											) : (
												<div>
													{
														state.response.status ? (
															<div className="alert alert-success mt-3">
																<pre className="mb-0">{JSON.stringify(state.response, null, 2)}</pre>
															</div>
														) : (
															<div className="alert alert-warning mt-3">
																<pre className="mb-0">{state.response}</pre>
															</div>
														)
													}
												</div>
											)
										}
									</div>
								) : false
							}
						</form>
					</div>

					<div className="mt-4">
						<table className="table table-hover">
							<thead>
								<tr>
									<th scope="col" width="17%">Instruction</th>
									<th scope="col" width="23%">Value</th>
									<th scope="col" width="30%">Examples<span className="d-inline d-lg-none">, Comments</span></th>
									<th scope="col" width="30%" className="d-none d-lg-table-cell">Comments</th>
								</tr>
							</thead>
							<tbody>
								<tr className={reference.symbol?.required ? '' : 'text-secondary'}>
									<td>symbol</td>
									<td><i>string</i></td>
									<td>
										{reference?.symbol?.examples.map((item, index) =>
											<div key={index} className="text-nowrap">
												&quot;{item}&quot;
												{reference?.symbol?.examplesClass?.[index] ? <span className="text-secondary">&nbsp;({config.order.class.labels[reference.symbol.examplesClass[index]]})</span> : ''}
												{reference?.symbol?.examplesAccount?.[index] ? <span className="text-secondary">&nbsp;({reference.symbol.examplesAccount[index]})</span> : ''}
												{reference?.symbol?.examplesComment?.[index] ? <span className="text-secondary">&nbsp;({reference.symbol.examplesComment[index]})</span> : ''}
												{',\n'}
												{/* {index + 1 < reference?.symbol?.examples.length ? ',\n' : ''} */}
											</div>
										)}

										<div key="trendspider-bot" className="text-nowrap"><small>&quot;%bot_symbol%&quot;&nbsp;<span className="text-secondary">{'(TrendSpiderBot),\n'}</span></small></div>
										<div key="trendspider-alert" className="text-nowrap"><small>&quot;%alert_symbol%&quot;&nbsp;<span className="text-secondary">{'(TrendSpiderAlert),\n'}</span></small></div>
										<div key="tradingview" className="text-nowrap"><small>{'"{{ticker}}"'}&nbsp;<span className="text-secondary">{'(TradingView)\n'}</span></small></div>

										<div className="d-block d-lg-none mt-3"><small>{reference.symbol.comments?.join('; ')}</small></div>
									</td>
									<td className="d-none d-lg-table-cell" style={{whiteSpace: 'pre-line'}}>{reference.symbol.comments?.join('; \n')}</td>
								</tr>

								<tr className={reference.action?.required ? '' : 'text-secondary'}>
									<td>action</td>
									<td>{reference.action.values.join(', ')}</td>
									<td>
										<div className="text-nowrap">&quot;{reference.action.values[0]}&quot;{/* ',\n' */}</div>
										{/* <div key="tradingview" className="text-nowrap"><small>{'"{{strategy.order.action}}"'}&nbsp;<span className="text-secondary">{'(TradingView)\n'}</span></small></div> */}
										<div className="d-block d-lg-none mt-3"><small>{reference.action.comments?.join('; ')}</small></div>
									</td>
									<td className="d-none d-lg-table-cell">{reference.action.comments?.join('; ')}</td>
								</tr>

								<tr className={reference.quantity?.required ? '' : 'text-secondary'}>
									<td>quantity</td>
									<td><i>number</i></td>
									<td>
										<div className="text-nowrap">{reference.quantity.examples[0]}{/* ',\n' */}</div>
										{/* <div key="tradingview" className="text-nowrap"><small>{'{{strategy.position_size}}'}&nbsp;<span className="text-secondary">{'(TradingView)\n'}</span></small></div> */}
										{/* <div key="tradingview" className="text-nowrap"><small>{'"{{strategy.order.contracts}}"'}&nbsp;<span className="text-secondary">{'(TradingView)\n'}</span></small></div> */}

										<div className="d-block d-lg-none mt-3"><small>{reference.quantity.comments?.join('; ')}</small></div>
									</td>
									<td className="d-none d-lg-table-cell">{reference.quantity.comments?.join('; ')}</td>
								</tr>

								{
									reference.quantity_type?.values?.length && (
										<tr>
											<td className={reference.quantity_type?.required ? '' : 'text-secondary'}>quantity_type</td>
											<td className={reference.quantity_type?.required ? '' : 'text-secondary'}>{reference.quantity_type.values.join(', ')}</td>
											<td className={reference.quantity_type?.required ? '' : 'text-secondary'}>&quot;{reference.quantity_type.examples[0]}&quot; <div className={'d-block d-lg-none mt-3 ' + (reference.quantity_type?.required ? '' : 'text-secondary')}><small>{reference.quantity_type.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.quantity_type?.required ? '' : 'text-secondary')}>{reference.quantity_type.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.type?.values?.includes('limit') && (
										<tr>
											<td className={reference.limit_price?.required ? '' : 'text-secondary'}>limit_price</td>
											<td className={reference.limit_price?.required ? '' : 'text-secondary'}><i>number,<br/>expression</i></td>
											<td className={reference.limit_price?.required ? '' : 'text-secondary'}>{reference.limit_price.examples.map((item, index) => <div key={index}>{item}</div>)} <div className={'d-block d-lg-none mt-3 ' + (reference.limit_price?.required ? '' : 'text-secondary')}><small>{reference.limit_price.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.limit_price?.required ? '' : 'text-secondary')}>{reference.limit_price.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.type?.values?.some(item => (/^stop/).test(item)) && (
										<tr>
											<td className={reference.stop_price?.required ? '' : 'text-secondary'}>stop_price</td>
											<td className={reference.stop_price?.required ? '' : 'text-secondary'}><i>number,<br/>expression</i></td>
											<td className={reference.stop_price?.required ? '' : 'text-secondary'}>{reference.stop_price.examples.map((item, index) => <div key={index}>{item}</div>)} <div className={'d-block d-lg-none mt-3 ' + (reference.stop_price?.required ? '' : 'text-secondary')}><small>{reference.stop_price.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.stop_price?.required ? '' : 'text-secondary')}>{reference.stop_price.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.stop_price_distance && (
										<tr>
											<td className={reference.stop_price_distance_?.required ? '' : 'text-secondary'}>stop_price_distance</td>
											<td className={reference.stop_price_distance?.required ? '' : 'text-secondary'}><i>number,<br/>expression</i></td>
											<td className={reference.stop_price_distance?.required ? '' : 'text-secondary'}>{reference.stop_price_distance.examples.map((item, index) => <div key={index}>{item}</div>)} <div className={'d-block d-lg-none mt-3 ' + (reference.stop_price_distance?.required ? '' : 'text-secondary')}><small>{reference.stop_price_distance.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.stop_price_distance?.required ? '' : 'text-secondary')}>{reference.stop_price_distance.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.stop_price_percent && (
										<tr>
											<td className={reference.stop_price_percent?.required ? '' : 'text-secondary'}>stop_price_percent</td>
											<td className={reference.stop_price_percent?.required ? '' : 'text-secondary'}><i>number,<br/>expression</i></td>
											<td className={reference.stop_price_percent?.required ? '' : 'text-secondary'}>{reference.stop_price_percent.examples.map((item, index) => <div key={index}>{item}</div>)} <div className={'d-block d-lg-none mt-3 ' + (reference.stop_price_percent?.required ? '' : 'text-secondary')}><small>{reference.stop_price_percent.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.stop_price_percent?.required ? '' : 'text-secondary')}>{reference.stop_price_percent.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.take_profit_price && (
										<tr>
											<td className="text-secondary">take_profit_price</td>
											<td className="text-secondary"><i>number,<br/>expression</i></td>
											<td className="text-secondary">{reference.take_profit_price.examples.map((item, index) => <div key={index}>{item}</div>)} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.take_profit_price.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.take_profit_price.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.stop_loss_price && (
										<tr>
											<td className="text-secondary">stop_loss_price</td>
											<td className="text-secondary"><i>number,<br/>expression</i></td>
											<td className="text-secondary">{reference.stop_loss_price.examples.map((item, index) => <div key={index}>{item}</div>)} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.stop_loss_price.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.stop_loss_price.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.stop_loss_price_distance && (
										<tr>
											<td className="text-secondary">stop_loss<wbr />_price_distance</td>
											<td className="text-secondary"><i>number,<br/>expression</i></td>
											<td className="text-secondary">{reference.stop_loss_price_distance.examples.map((item, index) => <div key={index}>{item}</div>)} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.stop_loss_price_distance.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.stop_loss_price_distance.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.stop_loss_price_percent && (
										<tr>
											<td className="text-secondary">stop_loss<wbr />_price_percent</td>
											<td className="text-secondary"><i>number,<br/>expression</i></td>
											<td className="text-secondary">{reference.stop_loss_price_percent.examples.map((item, index) => <div key={index}>{item}</div>)} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.stop_loss_price_percent.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.stop_loss_price_percent.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.advancedType && (
										<tr>
											<td className="text-secondary">type</td>
											<td className="text-secondary">{reference.advancedType.values.join(', ')}</td>
											<td className="text-secondary">&quot;{reference.advancedType.values[0]}&quot;</td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.advancedType.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.duration && (
										<tr>
											<td className="text-secondary">duration</td>
											<td className="text-secondary">{reference.duration.values.join(', ')}</td>
											<td className="text-secondary">&quot;{reference.duration.values[0]}&quot;</td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.duration.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.client_id && (
										<tr>
											<td className="text-secondary">client_id</td>
											<td className="text-secondary"><i>string or number</i></td>
											<td className="text-secondary">{reference.client_id.examples[0]} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.client_id.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.client_id.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.parent_id && (
										<tr>
											<td className="text-secondary">parent_id</td>
											<td className="text-secondary"><i>string or number</i></td>
											<td className="text-secondary">{reference.parent_id.examples[0]} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.parent_id.comments?.join('; ')}</small></div></td>
											<td className="d-none d-lg-table-cell text-secondary">{reference.parent_id.comments?.join('; ')}</td>
										</tr>
									)
								}

								{
									reference.class?.comments && ( // yep, such a weird condition
										<tr>
											<td className={reference.class?.required ? '' : 'text-secondary'}>class</td>
											<td className={reference.class?.required ? '' : 'text-secondary'}>{reference.class.values.join(', ')}</td>
											<td className={reference.class?.required ? '' : 'text-secondary'}>&quot;{reference.class.values.includes('future') ? 'future' : reference.class.values[0]}&quot; <div className={'d-block d-lg-none mt-3 ' + (reference.class?.required ? '' : 'text-secondary')}><small>{reference.class.comments?.join('; ')}</small></div></td>
											<td className={'d-none d-lg-table-cell ' + (reference.class?.required ? '' : 'text-secondary')}>{reference.class.comments?.join('; ')}</td>
										</tr>
									)
								}

								<tr>
									<td className="text-secondary">extra</td>
									<td className="text-secondary"><i>object</i></td>
									<td className="text-secondary">{'{"a": "A", "b": "B", "x": 123}'} <div className="d-block d-lg-none mt-3 text-secondary"><small>{reference.extra.comments?.join('; ')}</small></div></td>
									<td className="d-none d-lg-table-cell text-secondary">{reference.extra.comments?.join('; ')}</td>
								</tr>
							</tbody>
						</table>

						{
							reference.comments?.length ?
								<div className="mt-2 text-secondary">
									{reference.comments?.join('; ')}
								</div>
								: false
						}

						{
							reference.class?.values.includes('future') ?
								<div className="mt-2 text-secondary"><small>
									Future symbols should be formatted according to the widely accepted standard.
									The inclusion of additional delivery period formatting is permissible: the &quot;continuous&quot; period encoding (N!) can be used, or the period year can be denoted using 1-2-4 digits (2, 22, 2022).
									Examples of these are &quot;MES1!&quot;, &quot;MESU2022&quot;, &quot;MESU22&quot;, &quot;MESU2&quot; or &quot;MES&quot; (as an equivalent to &quot;MES1!&quot;).
								</small></div>
								: false
						}

						{
							reference.class?.values.includes('option') ?
								<div className="mt-2 text-secondary"><small>
									Option symbols should be formatted in line with the OCC standard.
									However, certain simplifications are allowed: you may use an underscore as a delimiter or even omit it altogether, and you may remove the &quot;zeros&quot; in prices for rounded values.
									Examples of these are &quot;AAPL 221121C00145000&quot;, &quot;AAPL_221121C145&quot;, &quot;AAPL221121C145&quot;.
								</small></div>
								: false
						}

						<div className="mt-2 text-secondary"><small>
							Time in force option, when applicable, is set to &quot;good till cancel&quot; (GTC) for limit and stop orders.
						</small></div>

						<div className="mt-2 text-secondary"><small>
							For details on how to use variables provided by different trading platforms, see more in the FAQ: <a href="https://help.signalstack.com/articles/trendspider-alerts-1eadaf83eaa84024" target="_blank" rel="noreferrer">TrendSpider</a>, <a href="https://help.signalstack.com/kb/signal-source-integrations/tradingview-alerts-f07455cf99ed63bd" target="_blank" rel="noreferrer">TradingView</a>.
						</small></div>
					</div>
				</div>
			) : false}

			<div className="mt-5">
				<div className="fw-bold">Response: Success (201)</div>

				<div className="alert alert-success mt-3">
					<pre className="mb-0">
						{JSON.stringify({
							id: 'ID12345',
							status: 'filled',
							symbol: 'AAPL',
							action: 'buy',
							quantity: 1,
							price: 123,
						}, null, 2)}
					</pre>
				</div>

				<table className="table table-hover">
					<thead>
						<tr>
							<th scope="col" width="17%">Instruction</th>
							<th scope="col" width="23%">Value</th>
							<th scope="col" width="30%">Examples<span className="d-inline d-lg-none">, Comments</span></th>
							<th scope="col" width="30%" className="d-none d-lg-table-cell">Comments</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>id</td>
							<td><i>string</i></td>
							<td>&quot;ID12345&quot;</td>
							<td className="d-none d-lg-table-cell">Source brokerage order ID</td>
						</tr>
						<tr>
							<td>status</td>
							<td>accepted, filled</td>
							<td>&quot;filled&quot; <div className="d-block d-lg-none mt-3"><small>Extra &quot;draft&quot; value may be returned for test webhooks and calls</small></div></td>
							<td className="d-none d-lg-table-cell">Extra &quot;draft&quot; value may be returned for test webhooks and calls</td>
						</tr>
						<tr className="text-secondary">
							<td>status_description</td>
							<td><i>string</i></td>
							<td>&quot;Well done&quot; <div className="d-block d-lg-none mt-3"><small>Extra order processing details provided by brokerage, if any</small></div></td>
							<td className="d-none d-lg-table-cell">Extra order processing details provided by brokerage, if any</td>
						</tr>
						<tr className="text-secondary">
							<td>price</td>
							<td><i>number</i></td>
							<td>123 <div className="d-block d-lg-none mt-3"><small>Average fill price, if any</small></div></td>
							<td className="d-none d-lg-table-cell">Average fill price, if any</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className="mt-5">
				<div className="fw-bold">Response: Error (400)</div>

				<div className="alert alert-danger mt-3">
					<pre className="mb-0">
						{JSON.stringify({
							status: 'BaseError',
							message: 'No symbol found',
						}, null, 2)}
					</pre>
				</div>

				<table className="table table-hover">
					<thead>
						<tr>
							<th scope="col" width="17%">Instruction</th>
							<th scope="col" width="23%">Value</th>
							<th scope="col" width="30%">Examples<span className="d-inline d-lg-none">, Comments</span></th>
							<th scope="col" width="30%" className="d-none d-lg-table-cell">Comments</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>status</td>
							<td>ValidationError, ExecutionError, AuthenticationError, ForbiddenError, BaseError</td>
							<td>&quot;BaseError&quot; <div className="d-block d-lg-none mt-3"><small>Based on the brokerage response status and more</small></div></td>
							<td className="d-none d-lg-table-cell">Based on the brokerage response status and more</td>
						</tr>
						<tr className="text-secondary">
							<td>message</td>
							<td><i>string</i></td>
							<td>&quot;This order may result in an oversold/overbought position in your account&quot; <div className="d-block d-lg-none mt-3"><small>Extra error details provided by brokerage</small></div></td>
							<td className="d-none d-lg-table-cell">Extra error details provided by brokerage</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
}
/* eslint-enable complexity */

export default withRouter(MeConnector);
