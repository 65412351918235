import React, { useContext, useState, useEffect } from 'react';

import config from '../config';
import { useQuery, useLazyQuery } from '../hooks/graph';
import { SetupContext } from '../contexts/setup';
import Spinner from '../components/spinner';
const controller = new AbortController();

const LIMIT = 20;

function MeDashboardActionList() {
	const { providers } = useContext(SetupContext);
	const [ state, setState ] = useState({createdAtTo: null, togglers: {}});
	const { data } = useQuery('actions', {variables: {createdAtTo: state.createdAtTo}, pollInterval: 600 * 1000}, controller); // 10m
	const [ getMore, { data: dataMore, loading: loadingMore } ] = useLazyQuery('actions', controller);

	const onToggle = id => {
		setState({...state,
			togglers: {...state.togglers, [id]: !state.togglers[id]},
		});
	};

	useEffect(() => {
		data?.actions?.length && setTimeout(() => setState({...state,
			actions: [].concat(data.actions, state.actions || []),
			createdAtTo: data.actions[0].createdAt,
		}), 0);
	}, [data]);

	useEffect(() => {
		dataMore?.actions.length && setTimeout(() => setState({...state,
			actions: state.actions.concat(dataMore.actions),
		}), 0);
	}, [dataMore]);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return state && state.actions?.length ? (
		<div className="mb-4">

			{/*
				// temporal, 240228
				// template for temporal notifications (don't remove)
				state.actions.find(item => item.metadata?.includes('misconfiguration')) && (
					<div className="alert alert-warning mt-3" role="alert">
						<div><strong>Interactive Brokers</strong></div>
						There is a partial authentication outage on the broker end. We have contacted their support and they have assured us they are working to fix this as quickly as possible.
					</div>
				)
			*/}

			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col">Last actions</th>
						<th scope="col" className="d-none d-lg-table-cell">Source</th>
						<th scope="col">Date<span className="d-inline d-lg-none">, Source</span></th>
					</tr>
				</thead>
				{
					state.actions?.length ? (
						<tbody>
							{
								state.actions.map(item =>
									<tr key={item.id}>
										<td>{actionDescription(providers, item, state.togglers[item.id], onToggle)}</td>
										<td className="d-none d-lg-table-cell" style={{maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.address || '-'}</td>
										<td className="text-nowrap">{new Date(Math.round(item.createdAt / 1000) * 1000).toLocaleString()} <div className="d-block d-lg-none" style={{maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.address || '-'} <span className="text-secondary">{config.platform.labels[config.platform.addresses[item.address]] || ''}</span></div></td>
									</tr>
								)
							}
						</tbody>
					) : false
				}
			</table>

			{
				state.actions.length >= LIMIT && (
					<div className="mt-3 text-center"><a href="#" onClick={event => { event.preventDefault(); !loadingMore && getMore({variables: {createdAtFrom: state.actions[state.actions.length - 1].createdAt}}); }} role="button" className="btn btn-outline-primary"><Spinner show={loadingMore}>More</Spinner></a></div>
				)
			}
		</div>
	) : false;
}

function actionDescription(providers, action, toggler, onToggle) {
	const metadata = JSON.parse(action.metadata);
	const isMetadata = Object.keys(metadata)?.length > 0;

	return ({
		'Signin': () => `You signed in to the dashboard`,
		'AuthPasswordReset': () => `You reset the password`,
		/* to be dropped */ 'ConnectorCreate': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} connected` : action.code,
		'ConnectorActivate': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} connected` : action.code,
		'ConnectorDelete': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} disconnected` : action.code,
		'HookCreate': () => isMetadata ? `Webhook ${metadata?.shortKey || metadata.key} ${metadata.name ? '(' + metadata.name + ')' : ''} created for ${providers[metadata.providerName]?.title || 'Brokerage'} ${metadata.account ? '#' + metadata.account : ''}` : action.code,
		'HookDelete': () => isMetadata ? `Webhook ${metadata?.shortKey || metadata.key} ${metadata.name ? '(' + metadata.name + ')' : ''} removed from ${providers[metadata.providerName]?.title || 'Brokerage'} ${metadata.account ? '#' + metadata.account : ''}` : action.code,
		'OrderCreate': () => <span onClick={event => { event.preventDefault(); onToggle(action.id); }} role="button">Webhook {metadata.hook?.shortKey} ({providers[metadata.connector?.providerName]?.title || 'Brokerage'}{metadata.hook?.account ? '#' + metadata.hook.account : ''}) {metadata.response?.status == 'rejected' || metadata.response?.error ? 'call failed' : 'called'} <span className={'collapse' + (toggler ? ' show' : '')}><br/> Request: <br/> <code className="alert alert-secondary d-inline-block p-1 mb-0">{JSON.stringify(metadata.request, null, ' ')}</code> <br/> Response: <br/> <code className={`alert alert-${metadata.response?.status?.includes('Error') ? 'danger' : 'success'} d-inline-block p-1 mb-0`}>{JSON.stringify(metadata.response, null, ' ')}</code></span></span>
	}[action.code] || (() => '-'))();
}

export default MeDashboardActionList;
